<template>
    <Dialog style="width: 50%" v-model:visible="display" @hide="close()">
        <Toast/>
        <template #header>
            <h3>{{title}}草稿箱</h3>
        </template>
        <div>
            <div class="p-grid p-fluid">
                <div class="p-col-6">
                    <div style="text-align: left">
                        <label>活动主题:</label>
                        <InputText v-model="from.title" class="p-mt-2"/>
                    </div>
                </div>
                <div class="p-col-6">
                    <div style="text-align: left">
                        <label>所属行业:</label>
                      <Dropdown class="p-mt-2" style="padding:0px; width: 100%;height: 33px" v-model="from.industry" :options="industry" option-label="name"
                                option-value="code"
                                placeholder="所属行业"/>
                    </div>
                </div>
                <div class="p-col-12">
                    <div style="text-align: left">
                        <label>消息简介:</label>
                        <InputText class="p-mt-2" v-model="from.brief"/>
                    </div>
                </div>

                <div class="p-col-12">
                  <label>内容：</label>
                    <div class="p-mt-2">
                      <my-editor :value="from.content" v-on:input="setContent"></my-editor>
<!--                        <Editor v-model="from.content" editorStyle="height: 220px"/>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="p-grid p-col-12 p-mt-6 p-jc-around">
            <Button label="取消" @click="display=false" class=""/>
            <Button label="保存" icon="pi pi-fw pi-save"  @click="submit"/>
        </div>
    </Dialog>
</template>

<script>
import myEditor from "@/views/backend/myEditor";
    export default {
      components:{myEditor},
        name: "",
        data() {
            return {
                title:false,
                display: false,
                from: {
                    id:null,
                    title: null,
                    name: null,
                    industry: null,
                    content:null,
                    brief:null,
                    messageType: null,
                },
              industry: []
            }
        },
      mounted() {
        this.$http.get('/dictionary/selectValue',{params:{key:'suoshuhangye'}}).then((res)=>{
          let list =res.data.myValue.split(",");
          let resList=[]
          for(let i in list){
            let item ={}
            item.name=list[i]
            item.code=list[i]
            resList.push(item)
          }
          this.industry=resList;
        })
      },
      methods: {
        setContent(val){
          this.from.content = val
        },
            init(info) {
                this.display = true
                if(info!=null){
                    this.title="编辑"
                    this.from = info
                }
            },
            close(){
                this.$emit('close',false)
            },
            submit() {
                this.$http.post('/send/editDrafts',this.from).then(() =>{
                    console.log(this.from)
                    this.display=false
                    this.$toast.add({severity: 'info', summary: '成功', detail: '操作成功', life: 3000})
                })
            },
        }
    }
</script>

<style scoped>

</style>
