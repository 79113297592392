<template>
  <div class="card">
    <Breadcrumb :model="items"/>
    <div class="p-mt-3">
      <DataTable ref="dt" :value="draftsMessage" v-model:selection="selectedProducts" dataKey="id"
                 :paginator="true" :rows="10" :filters="filters"
                 paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                 :rowsPerPageOptions="[5,10,25]"
                 currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计{totalRecords}记录">
        <template #header>
          <div class="p-d-flex p-col">
            <InputText v-model="title"  placeholder="关键词搜索"/>
            <Dropdown class="p-ml-3" v-model="name" :options="people" optionLabel="name" optionValue="id"
                      placeholder="发布人"
                      :showClear="true"/>
            <Dropdown class="p-ml-3"
                      v-model="industry"
                      :options="BelongIndustry" optionLabel="name" option-value="name"
                      placeholder="所属行业"
                      :showClear="true"/>
            <Button class="p-ml-2" @click="showDrafts">查询</Button>
          </div>
        </template>
        <Column field="id" header="序号" :sortable="true" headerStyle="width: 10rem"></Column>
        <Column field="title" header="活动主题" :sortable="false"></Column>
        <Column field="name" header="填写人" :sortable="false"></Column>
        <Column field="industry" header="所属行业" :sortable="false"></Column>
        <Column field="operation" header="操作" :exportable="false">
          <template #body="slotProps">
            <Button class="p-button-sm p-mr-2" @click="update(slotProps.data)">编辑</Button>
            <Button class="p-button-danger p-button-sm p-mr-2" @click="confirmDeleteProduct(slotProps.data)">删除</Button>
            <Button class="p-button-success p-button-sm p-mr-2" @click="confirmSendProducts(slotProps.data)">发送</Button>
          </template>
        </Column>
      </DataTable>
    </div>
    <Dialog v-model:visible="deleteProductDialog" :style="{width: '350px'}" header="确认" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
        <span v-if="product">你确认要删除 <b>{{ product.title }}</b>? 删除后不得再恢复</span>
      </div>
      <template #footer>
        <Button label="否" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
        <Button label="是" icon="pi pi-check" class="p-button-text" @click="deleteProduct(product.id)"/>
      </template>
    </Dialog>

    <Dialog header="提示" v-model:visible="sendModal" :style="{width: '20vw'}" :modal="true">
      <div style="text-align: center">
        <i style="fontSize: 3rem" class="pi pi-info-circle"/>
        <div class="p-mt-2">
          <span v-if="product">你确认要发送 <b>{{ product.title }}</b>?</span>
        </div>
      </div>
      <template #footer>
        <Button label="取消" icon="pi pi-times" @click="closeModal" class="p-button-text"/>
        <Button label="确认" icon="pi pi-check" @click="sendDrafts(product.id)" autofocus/>
      </template>
    </Dialog>
    <EditDrafts v-if="display" ref="update" v-on:close="diaLogClose"></EditDrafts>
  </div>
  <Toast/>
</template>

<script>
import EditDrafts from "@/views/platform/messagePush/EditDrafts";

export default {
  mounted() {
    this.showDrafts()
    this.adminList()
    this.getIndustry()
  },
  components: {EditDrafts},
  name: "",
  data() {
    return {
      title: null,
      name: null,
      industry: null,
      display: false,
      draftsMessage: [],
      people: [],
      BelongIndustry: [
      ],
      items: [
        {label: '消息推送', to: '/Drafts'},
        {label: '消息草稿箱', to: '/Drafts'}
      ],
      product: {},
      deleteProductDialog: false,
      filters: {},
      selectedProducts: null,
      sendModal: false,
    }
  },
  methods: {
    getIndustry(){
      this.$http.get('/dictionary/selectValue',{params:{key:'suoshuhangye'}}).then((res)=>{
        let list =res.data.myValue.split(",");
        let resList=[]
        for(let i in list){
          let item ={}
          item.name=list[i]
          resList.push(item)
        }
        console.log(resList)
        this.BelongIndustry=resList;
      })
    },
    confirmSendProducts(product) {
      this.product = product;
      this.sendModal = true;
    },
    closeModal() {
      this.sendModal = false;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct(id) {
      this.$http.get('/send/removeDrafts', {params: {'id': id}}).then((res) => {
        this.deleteProductDialog = false;
        this.draftsMessage = res.data
        this.$toast.add({severity: 'success', summary: '成功', detail: '删除成功', life: 3000});
        this.showDrafts()
      })
    },
    adminList() {
      this.$http.get('/adminUser/getList', {
        params: {
          name: ''
        }
      }).then((res) => {
        this.people = res.data
        console.log(this.people)
      })

    },
    showDrafts() {
      this.$http.get('/send/sendMessageList', {
        params: {
          state: 1,
          title: this.title,
          addUser: this.name,
          industry: this.industry,
          addTime: null,
        }
      }).then((res) => {
        this.draftsMessage = res.data
        console.log(res.data)
      })
    },
    sendDrafts(id) {
      this.$http.get('/send/sendDrafts', {params: {'id': id}}).then(() => {
        this.sendModal = false
        this.$toast.add({severity: 'success', summary: '成功', detail: '成功发送', life: 3000});
        this.showDrafts()
      })
    },
    update(info) {
      this.display = true
      this.$nextTick(() => {
        this.$refs.update.init(info)
      })
    },
    diaLogClose(e) {
      this.display = e
    },

    termQuery() {
      this.$http.get('/', {params: {'title': this.title, 'name': this.name, 'industry': this.industry}}).then((res) => {
        this.draftsMessage = res.data
        this.$toast.add({severity: 'success', summary: '成功', detail: '查询成功', life: 3000});
      })
    }
  }
}
</script>

<style scoped>

</style>